

























































import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';
import DnbWarning from '@/components/DnbWarning.vue';
import HelpText from '@/components/HelpText.vue';

export default Vue.extend({
  name: 'dnb-input',
  components: { DnbIcon, HelpText, DnbWarning },
  props: {
    value: [String, Number],
    id: String,
    label: String,
    validationError: Array,
    type: {
      type: String,
      default: 'text',
    },
    disable: { type: Boolean, default: false },
    placeholder: { required: false, type: [String, Number], default: '' },
    icon: { required: false, type: String, default: undefined },
    help: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    selectedHelpText: string;
    displayHelpText: boolean;
  } {
    return {
      selectedHelpText: '',
      displayHelpText: false,
    };
  },
  methods: {
    onInput(event: any) {
      this.$emit('input', event.target.value);
    },
    setSelectedHelpText(text: string) {
      this.selectedHelpText = text;
      this.displayHelpText = !this.displayHelpText;
      if (!this.displayHelpText) {
        this.selectedHelpText = '';
      }
    },
    resetHelpText() {
      this.selectedHelpText = '';
      this.displayHelpText = false;
    },
  },
});
