
































































import { ApiWithSubscribersDto } from '@portals/shared/admin/SubscriptionDto';
import Vue from 'vue';

import AdminPopUp from '@/components/AdminPopUp.vue';
import DnbButton from '@/components/DnbButton.vue';
import DnbInput from '@/components/DnbInput.vue';
import Loader from '@/components/Loader.vue';

import { fetchApisWtihSubscriptions } from '../../service/apiSubscriptionService';

type Data = {
  selected: string | undefined;
  apisWithSubscribers: ApiWithSubscribersDto[];
  loading: boolean;
  emailSearch: string;
};
export default Vue.extend({
  name: 'subscribers',
  components: { DnbButton, AdminPopUp, DnbInput, Loader },
  data(): Data {
    return {
      selected: undefined,
      apisWithSubscribers: [],
      loading: false,
      emailSearch: '',
    };
  },
  computed: {
    subscribers(): { [key: string]: string[] } {
      return this.apisWithSubscribers.reduce((object, item) => {
        // @ts-ignore
        object[item.apiId] = item.subscribers.filter((email) =>
          email.toLowerCase().includes(this.emailSearch.toLowerCase()),
        );
        return object;
      }, {});
    },
  },
  async mounted() {
    this.loading = true;
    this.apisWithSubscribers = await fetchApisWtihSubscriptions();
    this.loading = false;
  },
  methods: {
    togglePopUp(selected: string | undefined) {
      this.selected = selected;
    },
    getSubscribers(apiId: string) {
      const item = this.apisWithSubscribers.find(
        (item) => apiId === item.apiId,
      );
      return item ? item.subscribers.includes(this.emailSearch) : [];
    },
  },
});
