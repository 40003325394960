import { ApiWithSubscribersDto } from '@portals/shared/admin/SubscriptionDto';

import { request } from './request';

export async function fetchApisWtihSubscriptions(): Promise<
  ApiWithSubscribersDto[]
> {
  const response = await request<ApiWithSubscribersDto[]>({
    url: 'subscriptions',
  });
  return response.data;
}
